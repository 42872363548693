<template>
  <div>
    <Header />
    <div class="p-4">
      <div class="w-1/4 mb-4">
        <BlurredLogo />
      </div>
      <div class="w-1/4 mb-4">
        <Logo />
      </div>
      <div class="mb-4">
        <Checkbox id="checkbox1" label="Checkbox" value="Checkbox 1" v-model="checkbox"/>
      </div>
      <div class="mb-4">
        <Button type="primary">+ NEW REPORT</Button>
      </div>
      <div class="mb-4">
        <Button type="secondary-orange">Submit</Button>
      </div>
      <div class="mb-4">
        <Button type="secondary-blue">Submit</Button>
      </div>
      <div class="mb-4">
        <Button type="text" class="text-white">
          <template v-slot:iconLeft>
            <ReloadIcon class="text-gray-300 w-3" />
          </template>
          Reload
        </Button>
      </div>
      <div class="mb-4">
        <Button type="text-small" class="text-gray-300" @onClick="expanded = !expanded">
          {{ expanded ? 'Collapse' : 'Expand' }}
          <template v-slot:iconRight>
            <Chevron class="text-orange-400 mt-1 transform
                            transition-transform duration-200 linear"
                     :class="{'-rotate-180': expanded}"/>
          </template>
        </Button>
      </div>
      <div class="mb-4">
        <Button type="text-small" class="text-gray-100">
          <template v-slot:iconLeft>
            <ArrowLeft class="text-gray-300"/>
          </template>
          Back to Executive Report
        </Button>
      </div>
      <div class="w-1/4 mb-4">
        <TextInput label="Text Input" placeholder="Placeholder text..." v-model="inputText" />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import Button from '@/components/Button.vue';
import BlurredLogo from '@/assets/orbital_logo_blurred.svg?inline';
import Logo from '@/assets/orbital_logo.svg?inline';
import ReloadIcon from '@/assets/reload.svg?inline';
import Chevron from '@/assets/chevron.svg?inline';
import ArrowLeft from '@/assets/arrow_left.svg?inline';
import TextInput from '@/components/TextInput.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'Sandbox',
  components: {
    Button,
    BlurredLogo,
    Checkbox,
    Logo,
    ReloadIcon,
    Chevron,
    ArrowLeft,
    TextInput,
    Header,
  },
  data() {
    return {
      checkbox: true,
      expanded: false,
      inputText: '',
    };
  },
};
</script>
